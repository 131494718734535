.menu-item {
  list-style-type: none;
  border-bottom: 1px solid gray;
  color: white;
  margin-left: 0;
  padding: 8px;
  font-weight: 500;
  text-align: center;
}
.menu-container {
  margin: 0;
  padding: 0;

  background: #daa520;
}

.menuDisplay {
  display: none;
}
@media screen and (min-width: 600px) {
  .menu-container {
    display: none;
  }
}
