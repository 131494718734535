.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close {
  cursor: pointer;
  font-size: 20px;
}

.modal-content {
  margin-top: 10px;
}
.input-field {
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
}
.submit {
  width: 100%;
  background-color: #daa520;
  border-radius: 5px;

  margin-top: 10px;
  padding: 13px 0px;
}
