.banner {
  background-image: url("../assets/somany1.jpg");

  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 75vh;
}

.banner-content {
  height: 100%;

  transition: background-color 1s ease;
}

.banner div:hover {
  background-color: #daa520;
  cursor: pointer;

  width: 300px;
}
.estimate {
  background-color: #daa520;
  width: 40px;
  white-space: nowrap;
  overflow: hidden;
  transition: width 0.5s ease-in-out;
  display: inline-flex;
  align-items: center;

  float: inline-end;
  margin-top: 250px;
  border-radius: 30px 0px 0px 30px;

  font-size: 20px;
  font-weight: 600;
  padding: 15px;
}
.icon-fa {
  font-size: 50px;
  margin-right: 20px;
}

@media screen and (max-width: 600px) {
  .icon-fa {
    font-size: 25px;
    margin-right: 10px;
  }
  .estimate {
    width: 20px;

    margin-top: 50px;
    border-radius: 30px 0px 0px 30px;

    font-size: 15px;
    font-weight: 600;
    padding: 8px;
  }
  .estimate-effect {
    display: none;
  }
  .banner div:hover {
    background-color: #daa520;

    width: 200px;
  }
}
