.nav-container {
  width: 100%;
  background-color: black;

  height: 10vh;
  display: flex;
}
.logo-container {
  width: 70%;

  margin-left: 30px;
}

.nav-logo {
  height: 100px;
  margin-top: -10px;
}
.menu-button {
  color: #daa520;
  font-size: 40px;
  cursor: pointer;
}
.menu-button-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20%;
}
.nav-links,
.cta-button {
  display: none;
}

@media screen and (min-width: 960px) {
  .cta-button {
    background-color: #daa520;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 15px;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    padding: 10px 20px;
  }
  .icon-call {
    font-size: 20px;
  }
}

@media screen and (min-width: 600px) {
  .nav-container {
    width: 100%;
    background-color: black;

    height: 10vh;
    display: flex;
  }
  .menu-button-container {
    display: none;
  }
  /* .nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  .logo {
    width: 120px;
    filter: brightness(1.5);
  }
  .nav-links {
    list-style: none;
    display: flex;
    font-size: 15px;
    font-weight: 700;
  }
  .nav-links li {
    margin-right: 50px;

    width: 80px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-links a {
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
    height: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;

    display: flex;

    justify-content: center;
  }

  .nav-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .nav-links a:hover {
    color: #daa520;
  }
  .logo-container {
    width: auto;
  }
}
