.product-img {
  width: 300px;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}

.products-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
}
.products-container {
  max-width: 100%;
}
.product {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 300px;
}
.product-img :hover {
  transform: scale(1.2);
}
@media screen and (max-width: 600px) {
  .product {
    margin-bottom: 10px;
    width: 100%;
  }
  .product {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 200px;

    transition: width 0.5s ease-in-out;
    overflow: hidden;
  }
  .products-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    width: 100%;
  }
}
