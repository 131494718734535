.container1 {
  box-shadow: 0px 0px 30px 35px white;
  height: 8vh;
  display: block;
  justify-content: center;
}

.review-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.review {
  width: 200px;
  transition: transform 0.3s ease-in-out;
}

.review:hover {
  transform: scale(1.1);
}
@media screen and (min-width: 600px) {
  .review {
    width: 280px;
    transition: transform 0.3s ease-in-out;
  }
  .review:hover {
    transform: scale(1.3);
  }
  .container1 {
    height: 12vh;
  }
}
@media screen and (min-width: 900px) {
  .review {
    width: 280px;
    transition: transform 0.3s ease-in-out;
  }
  .review:hover {
    transform: scale(1.3);
  }
  .container1 {
    height: 12vh;
    display: flex;
  }
}
@media screen and (max-width: 900px) {
  .container1 {
    margin-bottom: 250px;
  }
}
@media screen and (max-width: 600px) {
  .container1 {
    margin-bottom: 150px;
  }
}
