@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@300&family=Rubik:wght@400;500;700&display=swap");

:root {
  --light: #daa520;
  --dark: #000;
}
body {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h3 {
  font-family: "Noto Serif Display", serif;
  font-size: 2.3rem;
  font-weight: 300;
  text-align: center;
}
a {
  text-decoration: none;
  color: var(--dark);
  font-weight: 400;
  transition: 0.3s ease-in;
  border-bottom: 1px solid transparent;
  margin-bottom: 0.5rem;
  display: inline-flex;
}
a:hover {
  border-bottom: 1px solid var(--dark);
}
ul {
  list-style-type: none;
  padding: 0;
}
button {
  appearance: none;
  border: 0;
  background: transparent;
}
.flex {
  display: flex;
}
.footer_video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  border: none;
}
.footer_inner {
  background: var(--light);
  backdrop-filter: blur(50px);
  border: 0.1px solid rgba(233, 232, 232, 0.208);
  border-radius: 5px;
  padding: 2rem;
}
.footer {
  position: relative;
  display: flex;
  align-items: center;
}
.container {
  width: 100%;
  margin: 0;
  padding: 0;
}

form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: min(100%, 400px);
  border-bottom: 1px solid var(--dark);
}

input {
  padding: 0.75rem 0;
  border: none;
  background: none;
  font-weight: 500;
  transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 0;
  width: 100%;
  font-size: 1.05rem;
  font-weight: bolder;
}
input:focus {
  outline: none;
}
input::placeholder {
  color: var(--dark);
}
@media (min-width: 675px) {
  .layout {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 2rem;
  }
  .w-50 {
    width: 50%;
  }
  .w-25 {
    width: 25%;
  }
}
form {
  position: relative;
}
svg {
  margin: 0.5rem;
}
.c-2 {
  margin-top: 3.5rem;
}
.footer_copyright {
  /* color: var(--light); */
  color: #daa520;
  text-align: center;
  background-color: black;
  margin: 0px;
  padding: 20px;
}
